import Image from "next/image";

function BounceAnimation(): JSX.Element {
  return (
    <div className="relative mx-auto flex max-w-hero2xl">
      <div className="absolute left-0 hidden w-full max-w-md animate-bounce-slow xl:block">
        <Image priority alt="background" height={800} src="/images/landing/hero/back_1.png" width={800} />
      </div>
      <div className="absolute right-0 mt-8 hidden w-full max-w-md animate-bounce-slow xl:block">
        <Image
          priority
          alt="background"
          className="relative"
          height={800}
          src="/images/landing/hero/back_2.png"
          width={800}
        />
      </div>
    </div>
  );
}

export default BounceAnimation;

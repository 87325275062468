import Meta from "components/Head/Meta";
import Title from "components/Head/Title";
import Layout from "components/Landing/Layout";
import { landingFAQ } from "components/SEO/FAQ/faq";
import Footer from "modules/landing/Components/V2/Footer";
import LandingHero from "modules/landing/Components/V2/LandingHero";
import styles from "styles/landing.module.scss";

import AdditionalFeatures from "./Components/AdditionalFeatures";
import CuratedLists from "./Components/CuratedLists";
import Questions from "./Components/Questions";
import LandingAbout from "./Components/V2/LandingAbout";
import Testimonials from "./Components/V2/Testimonials";

export default function Home(): JSX.Element {
  return (
    <Layout landing={styles.landing}>
      <>
        <Title title="Simplify | Your entire job search in one place." />
        <Meta
          content="Get matched with roles that fit you, autofill job applications anywhere on the web, browse curated job lists, & easily track your applications with Simplify."
          name="description"
        />
        <LandingHero />
        <LandingAbout />
        <Testimonials />
        <AdditionalFeatures />
        <CuratedLists />
        <Questions faq={landingFAQ} />
        <Footer />
      </>
    </Layout>
  );
}

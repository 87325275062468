import { ComponentType, useEffect } from "react";

import { isArray, isEmpty, isNil } from "lodash";
import { useRouter } from "next/router";

export default function AffiliateRoute(Component: ComponentType<React.PropsWithChildren<unknown>>): () => JSX.Element {
  function AR(): JSX.Element {
    const { query, replace } = useRouter();

    useEffect(() => {
      if (!isEmpty(query) && !isNil(query.invite) && !isArray(query.invite)) {
        // const secure = window.location.protocol === "https:" ? "secure;" : "";
        const domain = window.location.hostname === "localhost" ? "localhost" : `.${window.location.hostname}`;
        document.cookie = `referrer=${query.invite}; sameSite=lax; domain=${domain}; Max-Age=3888000`;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { invite, ...restQuery } = query;
        replace({ pathname: "/", query: restQuery }, undefined, { shallow: true });
      }
    }, [replace, query]);

    return <Component />;
  }
  return AR;
}

import { useEffect, useState } from "react";

import Image from "next/image";
import Link from "next/link";

import Animation from "components/Landing/Animation";
import Button from "components/Landing/Button";
import StarRating from "lib/StarRating";
import { INSTALL_PAGE } from "utils/constants";
import { getBrowserName } from "utils/helpers/helpers";

export default function LandingAbout(): JSX.Element {
  const [browserName, setBrowserName] = useState("Chrome");

  useEffect(() => {
    getBrowserName(setBrowserName);
  }, []);

  return (
    <section className="bg-white pt-16 sm:pt-4">
      <div className="mt-4 flex flex-col justify-center p-4 text-center text-5xl font-black text-secondary-400 sm:p-8 lg:text-6xl">
        <div className="inline-flex flex-col items-center justify-center">
          <div>
            <div>We&apos;re here for </div>
            <span className="relative z-0 inline-block">
              every step
              <svg
                className="relative -z-10 hidden lg:block"
                fill="none"
                height="8"
                viewBox="0 0 292 8"
                width="292"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.95277 4.64124C11.9989 3.84112 51.3789 2.00132 83.6468 0.963709C123.579 -0.319544 252.547 0.453077 273.884 2.10428C300.523 4.16523 297.716 5.68193 265.906 6.41376C212.218 7.65012 -0.00220121 7.61239 0 6.36685C0 5.72777 2.22985 4.9511 4.95277 4.64124Z"
                  fill="#3EC0DD"
                />
              </svg>
              <svg
                className="relative -z-10 block lg:hidden"
                fill="none"
                height="6"
                viewBox="0 0 242 6"
                width="242"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.10469 3.70282C9.9443 3.017 42.5812 1.44003 69.3237 0.550646C102.418 -0.549285 209.303 0.112961 226.986 1.52828C249.064 3.29481 246.738 4.59483 220.374 5.22212C175.88 6.28186 -0.00182429 6.24952 0 5.18191C0 4.63413 1.84802 3.96841 4.10469 3.70282Z"
                  fill="#3EC0DD"
                />
              </svg>
            </span>{" "}
            of your search.
          </div>
        </div>
      </div>
      <h2 className="mx-auto max-w-3xl px-4 text-center text-xl text-secondary-400">
        Tell us about your career history and goals. We&apos;ll help you craft a standout profile and help you land your
        dream job.
      </h2>
      <div className="mx-auto mt-4 flex max-w-7xl flex-col-reverse justify-between gap-8 p-4 py-16 sm:mt-0 sm:py-20 lg:flex-row lg:items-center">
        <div className="mx-auto flex max-w-xl flex-col gap-3 text-left text-secondary-400">
          <p className="flex items-center gap-4 text-xl text-secondary-300">
            <span className="flex size-10 items-center justify-center rounded-lg border border-gray-100 bg-gray-50 p-2">
              🔍
            </span>
            Job Matches
          </p>
          <h3 className="mt-2 text-4xl font-medium sm:text-5xl">Get matched to relevant jobs, personalized to you</h3>
          <p className="mt-3 text-lg">
            Forget endlessly scrolling on job boards. Tell us your preferences & dealbreakers and we&apos;ll match you
            with jobs that fit.
          </p>
          <Button
            className="mt-8 cursor-pointer self-start rounded-full border-2 border-primary-400 bg-primary-400 px-5 py-3 text-center text-base text-white transition-colors duration-500 ease-in-out hover:border-primary-500 hover:bg-primary-500 lg:w-56"
            to="/preferences?from=landing"
          >
            Get Matched Now
          </Button>
        </div>
        <div className="mx-auto max-w-[616px] overflow-hidden rounded-[20px] border border-gray-100">
          <Animation center animation="welcome2" stop={false} />
        </div>
      </div>
      <div className="w-full bg-primary-50 p-4 py-16">
        <div className="mx-auto flex max-w-7xl flex-col-reverse justify-between gap-8 lg:flex-row-reverse lg:items-center">
          <div className="mx-auto flex max-w-xl flex-col gap-3 text-left text-secondary-400">
            <p className="flex items-center gap-4 text-xl text-secondary-300">
              <span className="flex size-10 items-center justify-center rounded-lg border border-gray-100 bg-white p-2">
                ⚡️
              </span>
              Autofill Applications
            </p>
            <h3 className="mt-2 text-4xl font-medium sm:text-5xl">Autofill repetitive job application questions</h3>
            <p className="mt-3 text-lg">
              Install the Simplify Copilot extension to autofill your job applications & see keywords missing in your
              resume.
            </p>
            <div className="flex items-center gap-4">
              <Button
                external
                className="mt-8 cursor-pointer self-start rounded-full border-2 border-primary-400 bg-primary-400 px-5 py-3 text-center text-base text-white transition-colors duration-500 ease-in-out hover:border-primary-500 hover:bg-primary-500 lg:w-56"
                to={`${INSTALL_PAGE}?from=landing`}
              >
                Add to {browserName}
              </Button>
              <Link
                className="mt-8 cursor-pointer self-start rounded-full border-2 border-primary-500 bg-white px-5 py-3 text-center text-base text-primary-500 hover:border-primary-400 hover:bg-white hover:text-primary-400 lg:w-56"
                href="/copilot?from=landing"
                type="inverted"
              >
                Learn More
              </Link>
            </div>
            <div className="mt-8 flex flex-col items-center gap-2 2xs:flex-row sm:mt-4">
              <StarRating rating={5} />
              <p className="mt-px text-left text-sm text-secondary-400">
                {process.env.NEXT_PUBLIC_APPLICATION_COUNT}+ applications submitted
              </p>
            </div>
          </div>
          <div className="mx-auto w-full max-w-[616px]">
            <Animation center animation="autofill_blue" stop={false} />
          </div>
        </div>
      </div>
      <div className="mx-auto flex max-w-7xl flex-col-reverse justify-between gap-8 p-4 py-16 pb-8 sm:pb-10 sm:pt-20 lg:flex-row lg:items-center">
        <div className="mx-auto flex max-w-xl flex-col gap-3 text-left text-secondary-400">
          <p className="flex items-center gap-4 text-xl text-secondary-300">
            <span className="flex size-10 items-center justify-center rounded-lg border border-gray-100 bg-gray-50 p-2">
              📝
            </span>
            AI Resume Builder
          </p>
          <h3 className="mt-2 text-4xl font-medium sm:text-5xl">Craft the perfect tailored resume for every job</h3>
          <p className="mt-3 text-lg">
            Use AI to tailor your resume to fit the job description, see your resume ATS score, and identify missing
            keywords, all in a few clicks.
          </p>
          <Button
            className="mt-8 cursor-pointer self-start rounded-full border-2 border-primary-400 bg-primary-400 px-5 py-3 text-center text-base text-white transition-colors duration-500 ease-in-out hover:border-primary-500 hover:bg-primary-500 lg:w-56"
            to="/resume-builder"
          >
            Get a Free Resume
          </Button>
        </div>
        <div className="mx-auto w-full max-w-[616px]">
          <Animation center animation="welcome3" stop={false} />
        </div>
      </div>
      <div className="mx-auto flex max-w-7xl flex-col-reverse justify-between gap-8 bg-white p-4 pb-20 pt-8 sm:pt-10 lg:flex-row-reverse lg:items-center">
        <div className="mx-auto flex max-w-xl flex-col gap-3 text-left text-secondary-400">
          <p className="flex items-center gap-4 text-xl text-secondary-300">
            <span className="flex size-10 items-center justify-center rounded-lg border border-gray-100 bg-gray-50 p-2">
              💼
            </span>
            Job Tracker
          </p>
          <h3 className="mt-2 text-4xl font-medium sm:text-5xl">Bookmark jobs and track your search</h3>
          <p className="mt-3 text-lg">
            Goodbye spreadsheets. Bookmark job postings from 50+ job boards and manage your applications within our job
            tracker.
          </p>
          <Button
            className="mt-8 cursor-pointer self-start rounded-full border-2 border-primary-400 bg-primary-400 px-5 py-3 text-center text-base text-white transition-colors duration-500 ease-in-out hover:border-primary-500 hover:bg-primary-500 lg:w-56"
            to="/job-application-tracker"
          >
            Track Your Applications
          </Button>
        </div>
        <div className="mx-auto w-full max-w-[616px]">
          <Image alt="Job Tracker" height={1200} src="/images/landing/hero/job_tracker.png" width={1200} />
        </div>
      </div>
    </section>
  );
}

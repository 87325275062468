import Image from "next/image";

function AdditionalFeatures(): JSX.Element {
  return (
    <div className="container z-10 mx-auto flex max-w-7xl flex-col justify-between px-4 py-20">
      <div className="flex flex-col justify-center md:gap-x-40 lg:flex-row">
        <div className="mx-auto flex max-w-3xl flex-col justify-center md:mb-0">
          <div className="mb-8 text-center text-5xl font-black text-secondary-400 lg:text-6xl">
            <div className="inline-flex flex-col flex-wrap items-center justify-center">
              More tools to help you
              <div>
                {" "}
                <span className="relative">
                  stand out
                  <svg
                    className="absolute -bottom-1 left-0 -z-10 mb-1 hidden lg:block"
                    fill="none"
                    height="8"
                    viewBox="0 0 263 8"
                    width="263"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.46088 4.64124C10.8072 3.84112 46.2762 2.00132 75.3394 0.963709C111.306 -0.319544 227.465 0.453077 246.683 2.10428C270.677 4.16523 268.149 5.68193 239.498 6.41376C191.142 7.65012 -0.0019826 7.61239 0 6.36685C0 5.72777 2.00839 4.9511 4.46088 4.64124Z"
                      fill="#3EC0DD"
                    />
                  </svg>
                  <svg
                    className="absolute bottom-0 left-0 -z-10 mb-1 block lg:hidden"
                    fill="none"
                    height="6"
                    viewBox="0 0 211 6"
                    width="211"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.57888 3.70282C8.67044 3.017 37.1266 1.44003 60.4434 0.550646C89.2987 -0.549285 182.491 0.112961 197.909 1.52828C217.159 3.29481 215.131 4.59483 192.145 5.22212C153.35 6.28186 -0.0015906 6.24952 0 5.18191C0 4.63413 1.61129 3.96841 3.57888 3.70282Z"
                      fill="#3EC0DD"
                    />
                  </svg>
                </span>{" "}
                from the crowd.
              </div>
            </div>
          </div>
          <h2 className="mx-auto mb-6 max-w-3xl text-center text-xl text-secondary-400">
            Explore all the features we offer to supercharge your job or internship search.
          </h2>
        </div>
      </div>
      <div className="mt-12 grid grid-cols-1 gap-4 lg:grid-cols-6">
        <div className="col-span-3 mx-auto w-full max-w-xl overflow-hidden rounded-2xl border border-gray-100 bg-gray-50 lg:max-w-2xl">
          <div className="mx-auto w-full">
            <Image alt="ATS" height={200} src="/images/landing/additional_features/resume_ats.png" width={800} />
          </div>
          <h3 className="px-10 pt-2 text-[28px] font-bold text-secondary-400">Resume ATS Score</h3>
          <p className="px-10 pb-10 text-lg text-gray-400">See what&apos;s wrong with your resume and how to fix it</p>
        </div>
        <div className="col-span-3 mx-auto w-full max-w-xl overflow-hidden rounded-2xl border border-gray-100 bg-gray-50 lg:max-w-2xl">
          <div className="mx-auto w-full">
            <Image
              alt="Cover Letter"
              height={900}
              src="/images/landing/additional_features/cover_letter.png"
              width={800}
            />
          </div>
          <h3 className="px-10 pt-2 text-[28px] font-bold text-secondary-400">Cover Letter & Email Generator</h3>
          <p className="px-10 pb-10 text-lg text-gray-400">Craft personalized cover letters and networking emails</p>
        </div>
        <div className="col-span-3 mx-auto w-full max-w-xl overflow-hidden rounded-2xl border border-gray-100 bg-gray-50 lg:col-span-2 lg:max-w-2xl">
          <div className="mx-auto w-full">
            <Image
              alt="Cover Letter"
              height={900}
              src="/images/landing/additional_features/career_journal.png"
              width={800}
            />
          </div>
          <h3 className="px-10 pt-2 text-[28px] font-bold text-secondary-400">Career Journal</h3>
          <p className="px-10 pb-10 text-lg text-gray-400">
            Track all your career achievements and get promoted faster
          </p>
        </div>
        <div className="col-span-3 mx-auto w-full max-w-xl overflow-hidden rounded-2xl border border-gray-100 bg-gray-50 lg:col-span-2 lg:max-w-2xl">
          <div className="mx-auto w-full">
            <Image
              alt="Cover Letter"
              height={900}
              src="/images/landing/additional_features/career_connect.png"
              width={800}
            />
          </div>
          <h3 className="px-10 pt-2 text-[28px] font-bold text-secondary-400">Networking Copilot</h3>
          <p className="px-10 pb-10 text-lg text-gray-400">
            We help you find the hiring manager and send a personalized email
          </p>
        </div>
        <div className="col-span-3 mx-auto w-full max-w-xl overflow-hidden rounded-2xl border border-gray-100 bg-gray-50 lg:col-span-2 lg:max-w-2xl">
          <div className="mx-auto w-full">
            <Image
              alt="Cover Letter"
              height={900}
              src="/images/landing/additional_features/job_lists.png"
              width={800}
            />
          </div>
          <h3 className="px-10 pt-2 text-[28px] font-bold text-secondary-400">Job Lists</h3>
          <p className="px-10 pb-10 text-lg text-gray-400">
            Handpicked collections of the most exciting companies and opportunities
          </p>
        </div>
      </div>
    </div>
  );
}

export default AdditionalFeatures;

import { memo, useEffect, useRef, useState } from "react";

import Image from "next/image";

import Button from "components/Landing/Button";
import Nav from "components/Landing/Nav";
import StarRating from "lib/StarRating";

import BackgroundImage from "./BackgroundImage";
import { companies } from "./companies";

const tabs = [
  {
    title: "🔍 Job Matches",
    id: "job_matches",
    image: "job_matches",
  },
  {
    title: "⚡️ Copilot Extension",
    id: "autofill_extension",
    image: "autofill",
  },
  {
    title: "📝  AI Resume Builder",
    id: "resume_builder",
    image: "resume_builder",
  },
  {
    title: "💼  Job Tracker",
    id: "job_tracker",
    image: "job_tracker",
  },
];

// eslint-disable-next-line prefer-arrow-callback
const LandingHero = memo(function LandingHero() {
  const heroSection = useRef<HTMLElement>(null);
  const [toggleNav, setToggleNav] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const intervalIdRef = useRef<NodeJS.Timeout>(); // useRef to store the interval ID
  const selectedTab = tabs[selectedIndex] || tabs[0];

  const observerCallback = (entries: { isIntersecting: boolean }[]): void => {
    const [entry] = entries;
    setToggleNav(!entry.isIntersecting);
  };

  useEffect(() => {
    if (
      !("IntersectionObserver" in window) ||
      !("IntersectionObserverEntry" in window) ||
      !("intersectionRatio" in window.IntersectionObserverEntry.prototype)
    ) {
      // Load Polyfill if desired
    } else {
      const observer = new IntersectionObserver(observerCallback, {
        rootMargin: "0px",
      });
      if (heroSection.current) {
        observer.observe(heroSection.current);
      }
    }
  }, [heroSection]);

  useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      setSelectedIndex((prevIndex) => (prevIndex + 1) % tabs.length);
    }, 5000);

    // Cleanup interval on component unmount
    return (): void => clearInterval(intervalIdRef.current);
  }, []);

  // Fade Transitions
  const [currentImage, setCurrentImage] = useState(selectedTab);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isFadingIn, setIsFadingIn] = useState(true);

  useEffect(() => {
    if (selectedTab.id !== currentImage.id) {
      setIsFadingOut(true); // Start fade out
      setIsFadingIn(false); // Ensure the new image doesn't start fading in until fade-out is complete

      // Wait for the fade-out transition to finish before updating the image and starting fade-in
      const fadeTimeout = setTimeout(() => {
        setCurrentImage(selectedTab); // Update image after fade out
        setIsFadingOut(false); // Fade out completed
      }, 500); // Duration matches the fade-out CSS

      return (): void => clearTimeout(fadeTimeout);
    }
    return undefined;
  }, [selectedTab, currentImage]);

  return (
    <>
      <div className={`sticky top-0 z-50 w-full bg-white p-4 ${toggleNav ? "border-b border-gray-100" : ""}`}>
        <div className="container mx-auto max-w-hero">
          <Nav />
        </div>
      </div>
      <BackgroundImage />
      <section ref={heroSection} className="h-1" />
      <section className="relative mx-auto flex max-w-4xl flex-col overflow-hidden px-2 md:pb-8">
        <div className="container z-10 mx-auto flex max-w-hero flex-col justify-between sm:p-8 sm:pt-12">
          <div className="flex flex-col justify-center md:gap-x-40 lg:flex-row">
            <div className="mx-auto flex max-w-3xl flex-col justify-center">
              <div className="my-8 text-center text-5xl font-black text-secondary-400 sm:mt-0 lg:text-6xl">
                <div className="inline-flex flex-col flex-wrap items-center justify-center">
                  Your entire job search.
                  <div className="flex flex-col lg:flex-row lg:gap-4">
                    Powered by{" "}
                    <span className="relative">
                      one profile.
                      <svg
                        className="absolute -bottom-1 left-0 -z-10 block lg:hidden"
                        fill="none"
                        height="6"
                        viewBox="0 0 243 6"
                        width="243"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.12165 3.70282C9.98539 3.017 42.7571 1.44003 69.6102 0.550646C102.842 -0.549285 210.168 0.112961 227.924 1.52828C250.093 3.29481 247.757 4.59483 221.285 5.22212C176.606 6.28186 -0.00183183 6.24952 0 5.18191C0 4.63413 1.85566 3.96841 4.12165 3.70282Z"
                          fill="#3EC0DD"
                        />
                      </svg>
                      <svg
                        className="absolute -bottom-2 left-0 -z-10 hidden lg:block"
                        fill="none"
                        height="8"
                        viewBox="0 0 304 8"
                        width="304"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.15631 4.64124C12.492 3.84112 53.4904 2.00132 87.0843 0.963709C128.658 -0.319544 262.926 0.453077 285.139 2.10428C312.873 4.16523 309.951 5.68193 276.834 6.41376C220.94 7.65012 -0.00229167 7.61239 0 6.36685C0 5.72777 2.32148 4.9511 5.15631 4.64124Z"
                          fill="#3EC0DD"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <h1 className="mx-auto mb-12 max-w-3xl text-center text-xl text-secondary-400">
                Get personalized job recommendations, craft tailored resumes, autofill and track your job applications.
                We&apos;re here for every step of the process.
              </h1>
              <div className="flex flex-wrap gap-x-2 gap-y-4 sm:flex-nowrap lg:flex-wrap xl:flex-nowrap">
                <Button
                  className="mx-auto w-56 cursor-pointer self-start rounded-full border-2 border-primary-400 bg-primary-400 px-5 py-3 text-center text-base text-white transition-colors duration-500 ease-in-out hover:border-primary-500 hover:bg-primary-500"
                  id="click_preference_quiz"
                  to="/auth/register?from=landing"
                  type="wired"
                >
                  Sign Up – It&apos;s Free!
                </Button>
              </div>
              <div className="mt-12 flex flex-col items-center justify-center gap-2 2xs:flex-row">
                <StarRating rating={5} />
                <p className="mt-px text-sm text-gray-400">
                  Join {process.env.NEXT_PUBLIC_USER_COUNT}+ job seekers who use Simplify
                </p>
              </div>
            </div>
          </div>
        </div>
        <ul className="mx-auto mb-4 mt-10 flex w-full max-w-3xl gap-4 overflow-x-auto rounded-2xl border border-gray-100 bg-transparent p-1.5 text-center text-sm font-medium sm:bg-gray-50 md:mt-4">
          {tabs.map((tab, i) => (
            <li key={tab.id} className="w-full rounded-2xl bg-gray-50 p-2 sm:p-0">
              <button
                className={`inline-block size-full whitespace-nowrap rounded-xl border-gray-200 bg-gray-50 p-1.5 text-base font-bold transition-all duration-300 ease-in-out ${selectedTab.id === tab.id ? "border border-gray-200 bg-white" : "border border-transparent bg-gray-50 font-medium"}`}
                type="button"
                onClick={() => {
                  setSelectedIndex(i);
                  clearInterval(intervalIdRef.current);
                }}
              >
                {tab.title}
              </button>
            </li>
          ))}
        </ul>
        <div className="mx-auto mb-8 flex max-h-[562px] w-full max-w-3xl justify-center px-2">
          <Image
            key={currentImage.id}
            priority
            alt={currentImage.title}
            className={`transition-opacity duration-300 ease-in-out ${
              // eslint-disable-next-line no-nested-ternary
              isFadingOut ? "opacity-10" : isFadingIn ? "opacity-100" : "opacity-10"
            }`}
            height={562}
            src={`/images/landing/hero/${currentImage.image}.png`}
            width={752}
            onLoad={() => setIsFadingIn(true)}
          />
        </div>
        <div className="mt-8 flex flex-wrap items-center justify-center gap-8 px-2">
          {companies.map((company, i) => (
            <Image
              key={company.title}
              alt={company.title}
              className={`object-contain ${i === companies.length - 1 ? "2xs:hidden min-[485px]:block" : ""}`}
              height={40}
              src={company.logo}
              width={65}
            />
          ))}
        </div>
      </section>
    </>
  );
});

export default LandingHero;
